<template>
  <div class="file-upload">
    <Button
      type="submit"
      isFullWidth
      buttonStyle="primary"
      :disabled="!isVideoUploadValid()"
      size="large"
      @click="onUploadFiles">
      <template v-slot:content>
        <span class="button-text">{{$t('glossary:upload')}} {{videoFilesValid.length}} {{$t('glossary:file')}}</span>
      </template>
    </Button>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import Button from '@/modules/shared/components/atoms/button/Button.vue';

export default {
  props: {
    options: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    onCallbackAction: {
      type: Function,
    },
    geoblockPolicy: {
      type: Object,
      required: true,
    },
  },
  components: {
    Button,
  },
  data() {
    return {
      isButtonClicked: false,
    };
  },
  computed: {
    ...mapGetters([
      'videoFilesValid',
      'isEmptyVideoFiles',
      'isEmptyVideoFilesValid',
    ]),
  },
  methods: {
    isVideoUploadValid() {
      if (this.isButtonClicked || this.disabled || _.isEmpty(this.geoblockPolicy)) {
        return false;
      }

      const geoBlockType = _.get(this.geoblockPolicy, 'type', null);
      const geoBlockId = _.get(this.geoblockPolicy, 'ruleId', null);

      if (geoBlockType !== 'customRule' && !geoBlockId) {
        return false;
      }

      return !this.isEmptyVideoFilesValid && this.options.projectKey;
    },
    async onUploadFiles() {
      this.isButtonClicked = true;
      await this.$store.dispatch('createVideos', this.options);
      this.onCallbackAction();
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.button) {
  text-transform: capitalize;
}

.button-text {
  text-transform: capitalize;
}
</style>
