/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */

import { loadVideoByKey } from '@/modules/videoDetail/services';
import { FileWithValidator } from '@/types/File.type';
import _ from 'lodash';
import * as tus from 'tus-js-client';
import {
  fileValidator,
  filterFiles,
} from '@/modules/shared/utils/fileValidator';
import {
  differenceInMilliseconds,
  getTimeFromDate,
} from '@/modules/shared/utils/dateFormatter';

import tusUploader from '@/plugins/tus';

import { ActionContext } from 'vuex';
import { RootState } from '@/store/type';
import { Video } from '@/modules/shared/types/video.type';
import { IError } from '@/types/State.type';
import {
  isVideoProbeCompleted,
} from '../../videoDetail/utils/videoManagement';
import { VideoUploadState, VideoKeyGroupByState, VideoUploadingInfo } from '../types/videoUpload.type';
import { createVideos } from '../services';

type VideosUploadActionContext = ActionContext<VideoUploadState, RootState>

const initialState = (): VideoUploadState => ({
  videoFiles: [],
  videoResponses: [],
  videosUploadByKey: {},
  videoUploadKeys: [],
  videoKeysGroupByState: {
    queued: [],
    uploading: [],
    uploaded: [],
  },
  uploadVideoState: {
    isLoading: false,
    error: null,
  },
  firstRetryDelay: 5,
  maxRetryDelay: 160,
  maxVideosUpload: 3,
  deadlineTime: 240000,
  intervalTime: 20000,
  isDisplayProbeStatus: true,
  isDraggingVideoState: false,
  timer: {},
  isReuploadModalShowing: false,
  isChooseProjectForUploadModalShowing: false,
});

const state = initialState();

const getNextUploadKeyItems = (queueItems: string[], amountOfItems: number) => (_.take(queueItems, amountOfItems));

const getAmountOfUploading = (videoKeysGroupByState: VideoKeyGroupByState) => {
  if (videoKeysGroupByState.uploading) {
    return videoKeysGroupByState.uploading.length;
  }
  return 0;
};

const getAmountOfFailed = (videoKeysGroupByState: VideoKeyGroupByState) => {
  if (videoKeysGroupByState.failed) {
    return videoKeysGroupByState.failed.length;
  }
  return 0;
};

const mapVideoFilesToVideoObject = (videoFiles: FileWithValidator[], videoObjects: Video[]) => {
  const newVideoObjects = _.map(videoObjects, (videoObject, index) => (
    {
      ...videoObject,
      ...videoFiles[index],
      uploadingInfo: {
        state: 'queued',
      },
    }
  ));
  return newVideoObjects;
};

const getters = {
  videosUploadByKey: (state: VideoUploadState) => state.videosUploadByKey,
  getVideoFromVideosUpload: (state: VideoUploadState) => (videoObject: Video) => {
    const videoProcessingInfoState = _.get(videoObject, 'vod.processingInfo.state', '');
    const videoItemUpload: any = _.get(state, `videosUploadByKey[${videoObject?.key}]`, null);
    if (videoProcessingInfoState === 'sourceWaiting') {
      if (videoItemUpload) {
        return videoItemUpload;
      }
    }
    if (videoItemUpload) {
      if (videoItemUpload.uploadingInfo.state === 'uploading') {
        return videoItemUpload;
      }
      if (state.isDisplayProbeStatus) {
        if (videoItemUpload.uploadingInfo.state === 'uploaded') {
          return videoItemUpload;
        }
      }
    }
    return videoObject;
  },
  getVideoUploadByKey: (state: VideoUploadState) => (videoKey: string) => state.videosUploadByKey[videoKey],
  videoKeysGroupByState: (state: VideoUploadState) => state.videoKeysGroupByState,
  // videoFilesValid: (state) => state.videoFiles,
  videoFilesValid: (state: VideoUploadState) => filterFiles(state.videoFiles),
  videoFilesError: (state: VideoUploadState) => filterFiles(state.videoFiles, false),
  isEmptyVideosUpload: (state: VideoUploadState) => _.isEmpty(state.videoUploadKeys),
  isEmptyVideoFiles: (state: VideoUploadState) => _.isEmpty(state.videoFiles),
  isEmptyVideoFilesValid: (state: VideoUploadState, getters: any) => _.isEmpty(getters.videoFilesValid),
  isEmptyVideoResponses: (state: VideoUploadState) => _.isEmpty(state.videoResponses),
  uploadedVideo: (state: VideoUploadState) => state.videoKeysGroupByState.uploaded,
};

const mutations = {
  setUploadVideos(state: VideoUploadState, newVideoObjects: Video[]) {
    newVideoObjects.forEach((newVideoObject) => {
      if ((state.videosUploadByKey)[newVideoObject.key] !== undefined) {
        (state.videosUploadByKey)[newVideoObject.key] = { ...(state.videosUploadByKey)[newVideoObject.key], ...newVideoObject };
      } else {
        (state.videosUploadByKey)[newVideoObject.key] = { ...newVideoObject };
      }
    });
    // update store
    const newVideoUploadKeys = _.map(state.videosUploadByKey, (videoObject) => videoObject.key);
    state.videoUploadKeys = newVideoUploadKeys;
  },
  updateUploadVideoName(state: VideoUploadState, { videoKey, title }: { videoKey: string, title: string}) {
    state.videosUploadByKey[videoKey] = { ...state.videosUploadByKey[videoKey], title };
    // clone to new array
    // state.videosUploadByKey = [...state.videosUploadByKey];
  },
  updateUploadVideo(state: VideoUploadState, { videoKey, uploadingInfo }: { videoKey: string, uploadingInfo: VideoUploadingInfo}) {
    state.videosUploadByKey[videoKey] = { ...state.videosUploadByKey[videoKey], uploadingInfo };
    // clone to new array
    // state.videosUploadByKey = [...state.videosUploadByKey];
  },
  updateUploadVideoRetryDelay(state: VideoUploadState, { videoKey, currentRetryDelay }: { videoKey: string, currentRetryDelay: number}) {
    state.videosUploadByKey[videoKey] = { ...state.videosUploadByKey[videoKey], currentRetryDelay };
    // clone to new array
    // state.videosUploadByKey = [...state.videosUploadByKey];
  },
  setRemainingUploadVideoRetryDelay(state: VideoUploadState, { videoKey, remainingRetryDelay }: { videoKey: string, remainingRetryDelay: number}) {
    state.videosUploadByKey[videoKey] = { ...state.videosUploadByKey[videoKey], remainingRetryDelay };
    // clone to new array
    // state.videosUploadByKey = [...state.videosUploadByKey];
  },
  clearScheduleRetry(state: VideoUploadState, { videoKey }: { videoKey: string }) {
    clearInterval(state.videosUploadByKey[videoKey]?.retryTimerInterval || undefined);
    const clearScheduleRetry = { retryTimerInterval: null, remainingRetryDelay: null };
    state.videosUploadByKey[videoKey] = { ...state.videosUploadByKey[videoKey], ...clearScheduleRetry };
    // clone to new array
    // state.videosUploadByKey = [...state.videosUploadByKey];
  },
  setvideoKeysGroupByState(state: VideoUploadState) {
    state.videoKeysGroupByState = _.chain(state.videosUploadByKey)
      .groupBy((videoObject) => videoObject.uploadingInfo?.state)
      .mapValues((groupItems) => _.map(groupItems, (item) => item.key))
      .value() as any;
  },
  setUploadVideoStateMessage(state: VideoUploadState, message: { type: string, payload: IError}) {
    switch (message.type) {
      case 'error':
        state.uploadVideoState.error = message.payload;
        break;
      default:
        state.uploadVideoState.error = null;
        break;
    }
  },
  updateVideoFiles(state: VideoUploadState, videoFiles: FileWithValidator[]) {
    state.videoFiles = [...state.videoFiles, ...videoFiles];
  },
  updateVideoSingleFile(state: VideoUploadState, [newVideoFiles]: FileWithValidator[]) {
    state.videoFiles = [newVideoFiles];
  },
  removeVideoFile(state: VideoUploadState, deleteVideoFile: FileWithValidator) {
    state.videoFiles = _.filter(state.videoFiles, (videoFile) => videoFile !== deleteVideoFile);
  },
  destroyVideoFiles(state: VideoUploadState) {
    state.videoFiles = [];
  },
  clearDuplicatedVideoUploadKey(state: VideoUploadState, key: string) {
    if (key) {
      state.videoUploadKeys = _.without(state.videoUploadKeys, key);
      _.omit(state.videosUploadByKey, [key]);
      // _.transform(state.videosUploadByKey, (value: VideoUploadByKeyObject) => value.key === key);
    }
  },
  setLoadProbeStatusInterval(state: VideoUploadState, timerInfo: { timer: number; videoKey: string}) {
    state.timer[timerInfo.videoKey] = timerInfo.timer;
  },
  clearTimerInterval(state: VideoUploadState, videoKey: string) {
    clearInterval(state.timer[videoKey]);
  },
  updateVideoResponses(state: VideoUploadState, newVideoResponse: Video[]) {
    state.videoResponses = newVideoResponse;
  },
  destroyVideoResponses(state: VideoUploadState) {
    state.videoResponses = null;
  },
  resetVideosUploadState(state: VideoUploadState) {
    Object.assign(state, _.cloneDeep(initialState()));
  },
  setIsDraggingVideoState(state: VideoUploadState, newState: boolean) {
    state.isDraggingVideoState = newState;
  },
  setReuploadModalShowing(state: VideoUploadState, status: boolean) {
    state.isReuploadModalShowing = status;
  },
  setChooseProjectForUploadModalShowing(state: VideoUploadState, status: boolean) {
    state.isChooseProjectForUploadModalShowing = status;
  },
};

const actions = {
  updateVideoFiles({ commit }: VideosUploadActionContext, videoFiles: FileList) {
    const newVideoFiles = fileValidator(videoFiles);
    commit('updateVideoFiles', newVideoFiles);
  },
  updateVideoSingleFile({ commit }: VideosUploadActionContext, videoFiles: FileList) {
    const newVideoFiles = fileValidator(videoFiles);
    commit('updateVideoSingleFile', newVideoFiles);
  },
  removeVideoFile({ commit }: VideosUploadActionContext, deleteVideoFile: FileList) {
    commit('removeVideoFile', deleteVideoFile);
  },
  updateUploadVideoName({ commit }: VideosUploadActionContext, data: { videoKey: string, title: string }) {
    commit('updateUploadVideoName', data);
  },
  destroyVideoFiles({ commit }: VideosUploadActionContext) {
    commit('destroyVideoFiles');
  },
  async createVideos({ commit, dispatch, getters }: VideosUploadActionContext, options: any) {
    try {
      const response = await createVideos(getters.videoFilesValid, options);
      const videoObjects = mapVideoFilesToVideoObject(getters.videoFilesValid, response.data);
      dispatch('updateVideoResponses', response.data);
      dispatch('appendProjectVideo', _.head(response.data));
      dispatch('destroyVideoFiles');
      dispatch('setUploadVideos', videoObjects);
    } catch (error: any) {
      commit('setUploadVideoStateMessage', { type: 'error', payload: error.response });
    }
  },
  reuploadVideoSourceFile({ commit, dispatch, getters }: VideosUploadActionContext, payload: any) {
    try {
      const videoObjects = mapVideoFilesToVideoObject(getters.videoFilesValid, payload.videoObject);
      const { key } = videoObjects[0];
      if (getters.getVideoUploadByKey(key)) {
        commit('clearDuplicatedVideoUploadKey', key);
      }
      dispatch('destroyVideoFiles');

      videoObjects[0].updatedAt = (new Date()).toISOString();
      videoObjects[0].vod.source.probeErrorDetail = null;

      dispatch('setUploadVideos', videoObjects);
    } catch (error: any) {
      commit('setUploadVideoStateMessage', { type: 'error', payload: error.response });
    }
  },
  // eslint-disable-next-line object-curly-newline
  async uploadVideoItem({ dispatch, state, getters }: VideosUploadActionContext, videoKey: string) {
    try {
      const uploadItem = state.videosUploadByKey[videoKey];
      const videoFile = uploadItem.file;
      dispatch('setUploadVideosStartUploading', { videoKey });
      if (videoFile) {
        const uploadOptions = {
          metadata: {
            filename: videoFile.name,
            filetype: videoFile.type,
            videoKey,
          },
          onError(error: any) {
            // eslint-disable-next-line
            console.error('Error occurs when uploading video', error);
            dispatch('scheduleRetry', videoKey);
          },
          onProgress(bytesUploaded: number, bytesTotal: number) {
            dispatch('setUploadVideoProgress', { videoKey, progressEvent: { loaded: bytesUploaded, total: bytesTotal } });
          },
        };

        const newUploaderOptions = { ...tusUploader.options, ...uploadOptions };
        if (getters.formAccessToken) {
          newUploaderOptions.headers = { Authorization: getters.formAccessToken };
        }
        const newUploader = new tus.Upload(videoFile, newUploaderOptions);
        const previousUploads = await newUploader.findPreviousUploads();
        if (previousUploads.length) {
          const previousUploadsMatchNewUpload = previousUploads.find((previousUpload) => previousUpload.metadata.videoKey === newUploader.options.metadata?.videoKey);
          if (previousUploadsMatchNewUpload) {
            newUploader.resumeFromPreviousUpload(previousUploadsMatchNewUpload);
          }
        }

        // Start the upload
        newUploader.start();

        dispatch('loadVideoDetail', videoKey);
        if (state.isDisplayProbeStatus) {
          dispatch('setLoadProbeStatusInterval', videoKey);
        }
      }
    } catch (error) {
      dispatch('scheduleRetry', videoKey);
    }
  },
  setUploadVideos({ commit, dispatch }: VideosUploadActionContext, videoObjects: Video) {
    commit('setUploadVideos', videoObjects);
    commit('setvideoKeysGroupByState');
    dispatch('nextUploadItems');
  },
  uploadVideos({ dispatch }: VideosUploadActionContext, nextUploadKeyItems: string[]) {
    _.forEach(nextUploadKeyItems, (nextUploadvideoKey) => {
      dispatch('uploadVideoItem', nextUploadvideoKey);
    });
  },
  setUploadVideosStartUploading({ dispatch }: VideosUploadActionContext, { videoKey }: { videoKey: string }) {
    const uploadingInfo = {
      state: 'uploading',
      uploadedSize: 0,
      totalSize: 0,
      differenceTime: 0,
      startTimeStamp: getTimeFromDate(new Date()),
    };
    dispatch('updatevideoKeysGroupByState', { videoKey, uploadingInfo });
  },
  setUploadVideoProgress({ dispatch, getters }: VideosUploadActionContext, { videoKey, progressEvent }: { videoKey: string, progressEvent: ProgressEvent}) {
    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    // cal upload speed;
    const videoObject = getters.getVideoUploadByKey(videoKey);
    const startTimeStamp = _.get(videoObject, 'uploadingInfo.startTimeStamp', getTimeFromDate(new Date()));
    const differenceTime = differenceInMilliseconds(new Date(), new Date(startTimeStamp));

    if (progress === 100) {
      const uploadingInfo = {
        state: 'uploaded',
        progress,
        uploadedSize: progressEvent.loaded,
        totalSize: progressEvent.total,
        differenceTime,
        startTimeStamp,
        uploadCompleteTime: new Date(),
      };
      dispatch('updatevideoKeysGroupByState', { videoKey, uploadingInfo });
      dispatch('nextUploadItems');
    } else {
      const uploadingInfo = {
        state: 'uploading',
        progress,
        uploadedSize: progressEvent.loaded,
        totalSize: progressEvent.total,
        differenceTime,
        startTimeStamp,
      };
      dispatch('updatevideoKeysGroupByState', { videoKey, uploadingInfo });
    }
  },
  updatevideoKeysGroupByState({ commit }: VideosUploadActionContext, { videoKey, uploadingInfo }: { videoKey: string; uploadingInfo: VideoUploadingInfo}) {
    commit('updateUploadVideo', { videoKey, uploadingInfo });
    commit('setvideoKeysGroupByState');
  },
  updateUploadVideoProbeStatus({ commit }: VideosUploadActionContext, { videoKey, probeStatus }: { videoKey: string; probeStatus: string}) {
    commit('updateUploadVideoProbeStatus', { videoKey, probeStatus });
    commit('setvideoKeysGroupByState');
  },
  nextUploadItems({ state, dispatch }: VideosUploadActionContext) {
    const amountOfUploading = getAmountOfUploading(state.videoKeysGroupByState);
    const leftUploadSlot = (state.maxVideosUpload - amountOfUploading);
    if (leftUploadSlot > 0) {
      const nextUploadKeyItems = getNextUploadKeyItems(state.videoKeysGroupByState.queued, leftUploadSlot);
      dispatch('uploadVideos', nextUploadKeyItems);
    }
  },
  async scheduleRetry({ commit, state, dispatch }: VideosUploadActionContext, videoKey: string) {
    const currentRetryDelay = await dispatch('getNewRetryDelay', videoKey);

    commit('updateUploadVideoRetryDelay', { videoKey, currentRetryDelay });

    if (currentRetryDelay < state.maxRetryDelay) {
      // still retry
      const uploadingInfo = {
        state: 'retry',
        progress: null,
      };
      dispatch('updatevideoKeysGroupByState', { videoKey, uploadingInfo });
      dispatch('setRemainingUploadVideoRetryDelay', { videoKey });
    } else {
      // failed
      const uploadingInfo = {
        state: 'failed',
        progress: null,
      };

      dispatch('updatevideoKeysGroupByState', { videoKey, uploadingInfo });
      const amountOfFailed = getAmountOfFailed(state.videoKeysGroupByState);
      // if videos're failed more than 2 items, force all queued video to failed
      if (amountOfFailed > 2) {
        dispatch('forceAllUploadVideosToFailed', { uploadingInfo });
      } else {
        dispatch('nextUploadItems');
      }
    }
  },
  getNewRetryDelay({ state }: VideosUploadActionContext, videoKey: string) {
    const uploadItem = state.videosUploadByKey[videoKey];

    if (!uploadItem.currentRetryDelay || uploadItem.currentRetryDelay === 0) {
      return state.firstRetryDelay;
    }

    return uploadItem.currentRetryDelay * 2;
  },
  forceAllUploadVideosToFailed({ state, dispatch }: VideosUploadActionContext, { uploadingInfo }: { uploadingInfo: VideoUploadingInfo}) {
    _.forEach(state.videoKeysGroupByState.queued, (itemKey) => dispatch('updatevideoKeysGroupByState', { itemKey, uploadingInfo }));
  },
  forceRetryUploadAllVideo({ state, dispatch }: VideosUploadActionContext) {
    _.forEach(state.videoKeysGroupByState.failed, (videoKey) => dispatch('forceRetryUploadVideo', { videoKey }));
  },
  forceRetryUploadVideo({ dispatch }: VideosUploadActionContext, { videoKey }: { videoKey: string }) {
    const uploadingInfo = { state: 'queued' };
    dispatch('updatevideoKeysGroupByState', { videoKey, uploadingInfo });
    dispatch('nextUploadItems');
  },
  setRemainingUploadVideoRetryDelay({ commit, state, dispatch }: VideosUploadActionContext, { videoKey }: { videoKey: string}) {
    const uploadItem = state.videosUploadByKey[videoKey];
    const scheduledTimestamp = Math.floor(Date.now() / 1000) + (uploadItem.currentRetryDelay || 0);

    uploadItem.retryTimerInterval = setInterval(() => {
      const remainingRetryDelay = scheduledTimestamp - Math.floor(Date.now() / 1000);
      if (remainingRetryDelay < 0) {
        dispatch('uploadVideoItem', videoKey);
        dispatch('clearScheduleRetry', { videoKey });
      } else {
        commit('setRemainingUploadVideoRetryDelay', { videoKey, remainingRetryDelay });
      }
    }, 1000);
  },
  clearScheduleRetry({ commit, state }: VideosUploadActionContext, { videoKey }: { videoKey: string}) {
    const uploadItem = state.videosUploadByKey[videoKey];

    if (uploadItem.retryTimerInterval) {
      commit('clearScheduleRetry', { videoKey });
    }
  },
  async loadVideoDetail({ commit, dispatch, rootState }: VideosUploadActionContext, videoKey: string) {
    const response = await loadVideoByKey(videoKey);
    const videoData = response.data;

    await dispatch('mapVideoDataToUploadVideos', { videoKey, videoData });

    // If videoObject is currentVideo then updated currentVideo too
    const currentVideoKey = _.get(rootState.video?.currentVideo, 'key', null);
    if (currentVideoKey === videoData.key) {
      commit('setCurrentVideo', videoData, { root: true });
    }

    // await commit('setCurrentVideo', videoData, { root: true });

    if (isVideoProbeCompleted(videoData)) {
      commit('clearTimerInterval', videoData.key);
    }
  },
  async mapVideoDataToUploadVideos({ commit, getters }: VideosUploadActionContext, { videoKey, videoData }: { videoKey: string, videoData: Video}) {
    const videoUpload = getters.getVideoUploadByKey(videoKey);
    const newVideo = { ...videoData, uploadingInfo: videoUpload.uploadingInfo };
    await commit('setUploadVideos', [newVideo]);
  },
  async setLoadProbeStatusInterval({ commit, state, dispatch }: VideosUploadActionContext, videoKey: string) {
    const timer = setInterval(() => {
      const uploadItem = state.videosUploadByKey[videoKey];
      const uploadCompleteTime = _.get(uploadItem, 'uploadingInfo.uploadCompleteTime', null);
      if (uploadCompleteTime) {
        dispatch('loadVideoDetail', videoKey);
      }
    }, state.intervalTime);
    const timerInfo = {
      timer,
      videoKey,
    };

    commit('setLoadProbeStatusInterval', timerInfo);
  },
  destroyVideoResponses({ commit }: VideosUploadActionContext) {
    commit('destroyVideoResponses');
  },
  updateVideoResponses({ commit }: VideosUploadActionContext, videoResponses: Video[]) {
    commit('updateVideoResponses', videoResponses);
  },
  resetVideosUploadState({ commit }: VideosUploadActionContext) {
    commit('resetVideosUploadState');
  },
  setIsDraggingVideoState({ commit }: VideosUploadActionContext, boolean: boolean) {
    commit('setIsDraggingVideoState', boolean);
  },
  setReuploadModalShowing({ commit }: VideosUploadActionContext, status: boolean) {
    commit('setReuploadModalShowing', status);
  },
  setChooseProjectForUploadModalShowing({ commit }: VideosUploadActionContext, status: boolean) {
    commit('setChooseProjectForUploadModalShowing', status);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
